import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static values = {
    time: { type: String, default: '' },
    cooldown: { type: Number, default: 0 },
    originalEndTime: { type: Boolean, default: true },
    started: { type: Boolean, default: false }
  }

  connect() {
    this.getLatestCountdown()
  }

  updateCountdown() {
    let now = new Date().getTime()
    let endTime = new Date(this.timeValue).getTime()

    let distance = endTime - now
    let days = Math.floor(distance / (1000 * 60 * 60 * 24))
    if (days < 10 && days > 0) {
      days = `0${days}`
    }
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    if (hours < 10 && hours > 0) {
      hours = `0${hours}`
    }
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    if (minutes < 10 && minutes > 0) {
      minutes = `0${minutes}`
    }
    if (minutes == 0) {
      minutes = '00'
    }
    let seconds = Math.floor((distance % (1000 * 60)) / 1000)
    if (seconds < 10 && seconds > 0) {
      seconds = `0${seconds}`
    }

    if (distance < 0) {
      this.element.innerHTML = "NOW<br/><small>(REFRESH)</small>"
      setTimeout(this.getLatestCountdown.bind(this), this.cooldownValue)
      this.cooldownValue = 15000
    } else {
      this.element.innerHTML = `<div class='flex gap-x-2 items-center justify-center'>${days > 0 ? "<span class='text-3xl whitespace-nowrap md:text-6xl w-8 md:w-16'>" + days + "</span><span class='text-2xl md:text-5xl w-4 md:w-8'>:</span>" : ""}${(days > 0 || hours > 0) ? "<span class='text-3xl whitespace-nowrap md:text-6xl w-8 md:w-16'>" + hours + "</span><span class='text-2xl md:text-5xl w-4 md:w-8'>:</span>" : ""}<span class='text-3xl whitespace-nowrap md:text-6xl w-8 md:w-16'>${minutes}</span><span class='text-2xl md:text-5xl w-4 md:w-8'>:</span><span class='text-3xl whitespace-nowrap md:text-6xl w-8 md:w-16'>${seconds}</span></div>`
    }
  }

  getLatestCountdown() {
    fetch('https://files.thisisnotawebsitedotcom.com/is-it-time-yet/well-is-it.txt', { cache: 'no-store' })
    .then(response => response.text())
    .then(data => {
      if (data == 'NO') {
        // it is not time
        this.startCountdown()
      } else if (data.includes('https')) {
        window.location.replace(data)
      } else {
        this.originalEndTimeValue = false
        this.timeValue = data
        this.startCountdown()
      }
    })
    .catch(error => {
      this.startCountdown()
    })
  }

  startCountdown() {
    if (!this.startedValue) {
      this.updateCountdown()
      setInterval(this.updateCountdown.bind(this), 1000)
      this.startedValue = true
    }
  }
}
