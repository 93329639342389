import { Controller } from '@hotwired/stimulus'
import { Fancybox } from '@fancyapps/ui'
export default class extends Controller {
  static targets = [
    'button',
    'field',
    'bubble',
    'layer'
  ]
  static values = {
    line: { type: Number, default: 0 }
  }

  submit(e) {
    e.preventDefault()

    // Reset animations
    this.fieldTarget.classList.remove('animate-error')

    let code = e.target['code'].value
    code = code.toLowerCase()
    code = code.replace(/[^a-z0-9?]/gi, '')

    const existingContent = document.getElementById(code)

    if (existingContent) {
      this.fancyContent(code)
    } else if (code.length < 2) {
      this.flashError()
    } else {
      this.fieldTarget.setAttribute('readonly', true)
      this.buttonTarget.setAttribute('disabled', true)

      if (window.hasOwnProperty('plausible')) {
        window.plausible('Submit', { props: { code: code } })
      }

      const formData = new FormData()
      formData.append('code', code)
      fetch('https://mystery.thisisnotawebsitedotcom.com/', {
        method: 'POST',
        body: formData
      })
      .then(async response => {
        if (!response.ok) {
          this.flashError()
        } else {
          const contentType = response.headers.get('content-type')

          const newDiv = document.createElement('div')
          newDiv.setAttribute('id', code)
          newDiv.classList.add('hidden', 'html')
          newDiv.innerHTML = await response.text()
          document.body.appendChild(newDiv)
          this.fancyContent(code)
        }

        this.fieldTarget.removeAttribute('readonly')
        this.buttonTarget.removeAttribute('disabled')
      })
    }
  }

  flashError() {
    this.fieldTarget.offsetHeight
    this.fieldTarget.classList.add('animate-error')
  }

  fancyContent(code) {
    new Fancybox(
      [
        {
          src: `#${code}`,
          type: 'inline'
        },
      ], {
        backdropClick: false,
        Toolbar: {
          display: {
            left: [],
            middle: [],
            right: ['close'],
          },
        }
      }
    )
  }

  expandImage(e) {
    new Fancybox(
      [
        {
          src: e.target.src,
        },
      ], {
        backdropClick: 'toggleZoom',
        Toolbar: {
          display: {
            left: [],
            middle: [],
            right: ['close'],
          },
        }
      }
    )
  }

  randomizeError() {
    if (this.hasBubbleTarget) {
      const firstMessages = [
        "Wait...I run the Mystery Shack now. I'm gonna give myself a raise!",
        "Have infinite dollars, Soos! Thanks Soos!"
      ]
      if (this.lineValue < firstMessages.length) {
        this.bubbleTarget.innerHTML = firstMessages[this.lineValue]
        this.lineValue += 1
      } else {
        const messages = [
          "Man I'm hungry. Resisting...urge...to gnaw... on wires...",
          "Phew, I hope no one noticed how long that took!",
          "I'm not just a handy man, I'm the HANDIEST man.",
          "Boy, I could really go for eating some honey out of a clay pot with my bare hands right now.",
          "Now that I've fixed these wires, it's time to fix....America.",
          "I've earned a break! Time to finally download that app Mabels always telling me about, “Tik Tok.” I wonder if anyone's even heard of Gravity Falls on there!",
          "One time, my cousin Reggie tried to make a website and he accidentally like shut down the entire power grid of South Dakota. He's so good at computers dude"
        ]
        let diceRoll = Math.floor(Math.random() * messages.length)
        if (messages[diceRoll].includes('data:image')) {
          diceRoll = Math.floor(Math.random() * messages.length)
        }
        this.bubbleTarget.innerHTML = messages[diceRoll]
      }
    }
  }
}
